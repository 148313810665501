import * as Sentry from '@sentry/gatsby';
import axios from 'axios';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { SyntheticEvent, useState } from 'react';
import srcOgImage from '../../assets/academy/campaign-og-image.png';
import srcLogo from '../../assets/logotype.svg';
import Footer from '../../components/Academy/Footer';
import Button from '../../components/Form/Button';
import SEO from '../../components/SEO';
import { safe } from '../../utils';
import styles from './register.module.scss';

type DataProps = {
  site: {
    siteMetadata: {
      siteUrl: string;
    };
  };
};

export const campaignLandingAcademyRegisterPageQuery = graphql`
  query CampaignLandingAcademyRegisterPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const CampaignLandingAcademyRegisterPage = ({ data }: PageProps<DataProps>) => {
  const { t, originalPath, i18n, navigate } = useI18next();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastNameKana, setLastNameKana] = useState('');
  const [firstNameKana, setFirstNameKana] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [title, setTitle] = useState('');
  const [companyUrl, setCompanyUrl] = useState('');
  const [checkRegister, setCheckRegister] = useState(false);

  const canSubmit =
    lastName &&
    firstName &&
    lastNameKana &&
    firstNameKana &&
    email &&
    companyName &&
    companyUrl;

  const onSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    if (!canSubmit || submitting) {
      return false;
    }

    event.preventDefault();

    setSubmitting(true);

    const payload = {
      lastName: safe(lastName),
      firstName: safe(firstName),
      lastNameKana: safe(lastNameKana),
      firstNameKana: safe(firstNameKana),
      email: safe(email),
      companyName: safe(companyName),
      title: safe(title),
      companyUrl: safe(companyUrl),
      checkRegister: checkRegister,
    };

    const endpoint =
      process.env.GATSBY_CAMPAIGN_LANDING_ACADEMY_REGISTER_URL ||
      '/api/campaign/academy/application';

    axios
      .post(endpoint, payload)
      .then(() => {
        navigate('/academy/submitted', { replace: true });
      })
      .catch((error) => {
        Sentry.captureException(error);
        setSubmitting(false);
        setError('unexpected_error');
      });

    return false;
  };

  return (
    <div>
      <SEO
        title={`${t('campaign-academy-form-title').replace('<br />', '')} | ${t(
          'campaign-academy-title'
        )}`}
        description={t('campaign-academy-description')}
        keywords={t('campaign-academy-keywords')}
        lang={'ja'}
        audience={'merchant'}
        ogImage={`${data.site.siteMetadata.siteUrl}${srcOgImage}`}
        twitterImage={`${data.site.siteMetadata.siteUrl}${srcOgImage}`}
      />
      <div className={styles.topBanner}>
        <span>{t('campaign-academy-form-top-banner-2')}</span>
      </div>
      <div className={styles.mainWrapper}>
        <div className={styles.main}>
          <h1>
            <img
              src={srcLogo}
              width={347}
              className={styles.logo}
              alt={'Smartpay（スマートペイ）'}
            />
            <br />
            <Trans
              i18nKey="campaign-academy-form-title"
              components={{ br: <br /> }}
            ></Trans>
          </h1>
          <p className={styles.desc}>
            <Trans i18nKey="campaign-academy-form-title-readmore">
              業績アップコンペの参加規約は
              <Link
                to="/academy/terms"
                state={{ back: true }}
                className={styles.link}
              >
                こちら
              </Link>
            </Trans>
          </p>
          {/* Name */}
          <form className={styles.form} onSubmit={onSubmit}>
            <div className={styles.row}>
              <div className={styles.rowItem}>
                <label>
                  {t('campaign-academy-form-last-name')} <span>*</span>
                </label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={lastName}
                  onChange={(event) => setLastName(event.currentTarget.value)}
                  placeholder="田中"
                  required
                />
              </div>
              <div className={styles.rowItem}>
                <label>
                  {t('campaign-academy-form-first-name')} <span>*</span>
                </label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={firstName}
                  onChange={(event) => setFirstName(event.currentTarget.value)}
                  placeholder="かおる"
                  required
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.rowItem}>
                <label>
                  {t('campaign-academy-form-last-name-kana')} <span>*</span>
                </label>
                <input
                  type="text"
                  id="last_name_kana"
                  name="last_name_kana"
                  value={lastNameKana}
                  onChange={(event) =>
                    setLastNameKana(event.currentTarget.value)
                  }
                  placeholder="タナカ"
                  required
                />
              </div>
              <div className={styles.rowItem}>
                <label>
                  {t('campaign-academy-form-first-name-kana')} <span>*</span>
                </label>
                <input
                  type="text"
                  id="first_name_kana"
                  name="first_name_kana"
                  value={firstNameKana}
                  onChange={(event) =>
                    setFirstNameKana(event.currentTarget.value)
                  }
                  placeholder="カオル"
                  required
                />
              </div>
            </div>
            {/* Email */}
            <div className={styles.row}>
              <div className={styles.rowItem}>
                <label>
                  {t('campaign-academy-form-email')} <span>*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(event) => setEmail(event.currentTarget.value)}
                  placeholder="hi@smartpay.co"
                  required
                />
              </div>
            </div>
            {/* Brand */}
            <div className={styles.row}>
              <div className={styles.rowItem}>
                <label>
                  {t('campaign-academy-form-company-name')} <span>*</span>
                </label>
                <input
                  type="text"
                  id="company_name"
                  name="company_name"
                  value={companyName}
                  placeholder="Smartpay"
                  onChange={(event) =>
                    setCompanyName(event.currentTarget.value)
                  }
                  required
                />
              </div>
            </div>
            {/* Title */}
            <div className={styles.row}>
              <div className={styles.rowItem}>
                <label>{t('campaign-academy-form-contactor-title')}</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={title}
                  placeholder="デザイナー"
                  onChange={(event) => setTitle(event.currentTarget.value)}
                />
              </div>
            </div>
            {/* Website */}
            <div className={styles.row}>
              <div className={styles.rowItem}>
                <label>
                  {t('campaign-academy-form-company-url')} <span>*</span>
                </label>
                <input
                  type="text"
                  id="company_url"
                  name="company_url"
                  value={companyUrl}
                  placeholder="smartpay.co"
                  onChange={(event) => setCompanyUrl(event.currentTarget.value)}
                  required
                />
              </div>
            </div>
            {/* check for register */}
            <div className={styles.row}>
              <div className={styles.rowItem}>
                <p>
                  <Trans i18nKey="campaign-academy-form-company-privacy-policy-note">
                    取得する
                    <a
                      href="https://terms.smartpay.co/privacy/"
                      rel="noopener"
                      className={styles.link}
                    >
                      個人情報
                    </a>
                    は、業績アップコンペ参加者へのご連絡のため、その他当社のマーケティング活動のために使用します。
                  </Trans>
                </p>
              </div>
            </div>
            <Button
              type="submit"
              disabled={!canSubmit}
              loading={submitting}
              label={t('campaign-academy-form-submit')}
            />
            {error && (
              <div className={styles.errorWrapper}>
                <p id="error_message">{t('unexpected-error')}</p>
              </div>
            )}
          </form>
        </div>
      </div>

      <Footer t={t} />
    </div>
  );
};

export default CampaignLandingAcademyRegisterPage;
