import { Link, TFunction } from 'gatsby-plugin-react-i18next';
import { ReactElement } from 'react';
import srcIconLinkedin from '../../assets/icons/sns/linkedin.svg';
import srcIconX from '../../assets/icons/sns/x.svg';
import srcLogoWhite from '../../assets/logotype-white2.svg';
import styles from './Footer.module.scss';

const CampaignLandingAcademyFooter = ({
  t,
}: {
  t: TFunction;
}): ReactElement => {
  return (
    <div className={styles.footer}>
      <div>
        <div className={styles.left}>
          <Link
            to="/"
            title="欲しい未来の、出発点 | 後払い決済サービス Smartpay(スマートペイ)"
          >
            <img
              src={srcLogoWhite}
              height={23}
              loading="lazy"
              alt="Smartpay（スマートペイ）"
            />
          </Link>
        </div>
        <div className={styles.right}>
          <a
            href="https://twitter.com/smartpayjapan"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={srcIconX} width={24} loading="lazy" alt="Smartpay | X" />
          </a>
          <a
            href="https://www.linkedin.com/company/smartpay-co/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={srcIconLinkedin}
              width={24}
              loading="lazy"
              alt="Smartpay | Linkedin"
            />
          </a>
        </div>
      </div>

      <div>
        <div className={styles.address}>
          <div className={styles.location}>
            <span>{t('company-office-location-sec1')}</span>
            <span>{t('company-office-location-sec2')}</span>
          </div>
          {t('campaign-academy-footer-phone')}
        </div>
        <div className={styles.email}>
          <h3>{t('campaign-academy-footer-contact')}</h3>
          {t('campaign-academy-footer-merchant')}
          {t('colon')}
          <a href="mailto:support@smartpay.co">support@smartpay.co</a>
          <br />
          {t('campaign-academy-footer-customer')}
          {t('colon')}
          <a href="mailto:hello@smartpay.co">hello@smartpay.co</a>
        </div>
      </div>
      <div>
        <div className={styles.left}>
          <a
            href={'https://terms.smartpay.co/privacy'}
            rel="noopener"
            target="_blank"
          >
            {t('campaign-academy-footer-privacy')}
          </a>
        </div>
        <div className={styles.right}>
          &copy; {new Date().getFullYear()} {t('company-name-detail')}
        </div>{' '}
      </div>
    </div>
  );
};

export default CampaignLandingAcademyFooter;
